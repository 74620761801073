import React from "react";

import PromoBlock from "@/components/common/promoBlock";
import { useBanners } from "@/hooks/useBanners";
import Breadcrumbs from "@components/common/Breadcrumbs";
import { BreadcrumbUrlPrefixEnum } from "@components/common/Breadcrumbs/types";
import SeoHeaders from "@components/common/seoHeaders";
import { LinkUrlPrefixEnum } from "@components/textPages/types";

import Constructor from "../constructor";
import { getFirstImageFromZone } from "../constructor/utils";

import { LandingProps } from "./types";

import "./styles.scss";

const Landing = ({ pageContext }: LandingProps) => {
  const {
    seoId,
    url,
    title = "Lunaro",
    ogTitle,
    description = "Lunaro",
    breadcrumbDisplayName,
    zone,
    expertsInitial,
    wideBannerData,
  } = pageContext;
  const { checkIsWideBannerActive } = useBanners();
  const landingUrl = `${LinkUrlPrefixEnum.Landing}/${url}`;
  const wideBannerBannerIsShown = checkIsWideBannerActive(
    wideBannerData.wideBannerIsShownOnLanding,
    wideBannerData.wideBannerIsShownOnlyForAuthorized,
    wideBannerData.wideBannerShowOnProdFlag,
    wideBannerData.wideBannerIsShownWithFreeSession,
  );
  return (
    <>
      <SeoHeaders
        title={title}
        ogTitle={ogTitle}
        description={description}
        url={landingUrl}
        imageUrl={getFirstImageFromZone(zone)}
      />
      {wideBannerBannerIsShown && <PromoBlock wideBannerData={wideBannerData} />}
      <div className="landing">
        <div className="page-width">
          <Breadcrumbs
            seoId={seoId}
            url={url}
            breadcrumbDisplayName={breadcrumbDisplayName}
            breadcrumbs={[]}
            urlPrefix={BreadcrumbUrlPrefixEnum.Category}
            showOnPage={false}
          />
          <Constructor zone={zone} expertsInitial={expertsInitial} />
        </div>
      </div>
    </>
  );
};

export default Landing;
